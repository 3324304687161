import 'normalize.css'
import 'minireset.css'
import './App.scss'
import React, { ChangeEvent, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import TextareaAutosize from 'react-autosize-textarea'
import axios from 'axios'

const fadeInUp = ({ duration = 0.4, delay = 0.2, y = 40} = {}) => ({
  initial: { opacity: 0, y: y },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -y },
  transition: {
    delay,
    ease: [0.33, 1, 0.68, 1],
    duration,
  },
})

function App() {
  const [isApplicationFormVisible, setApplicationFormVisible] = useState(false)
  return (
    <div className="App">
      <header className="Header">
        <div className="Header-container">
          <div>
            <a href="/">
              <img
                src={require('./wordmark.svg').default}
                alt=""
                className="LogoWordmark"
              />
            </a>
          </div>
          <div>
            <img
              src={require('./logo.svg').default}
              alt=""
              className="LogoIcon"
            />
          </div>
        </div>
      </header>


      <div className="Hero">
        <div className="Hero-background">
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={`${require('./herobg-d.png').default}`}
            />
            <img
              alt="hero"
              src={require('./hero-m.png').default}
              className="Hero-image"
            />
          </picture>
        </div>

        {!isApplicationFormVisible && (
          <AnimatePresence>
            <motion.div
              {...fadeInUp()}
            >
              <HeroContent onApply={() => setApplicationFormVisible(true)} />
            </motion.div>
          </AnimatePresence>
        )}

        {isApplicationFormVisible && (
          <AnimatePresence>
            <motion.div {...fadeInUp()}>
              <ApplicationForm />
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </div>
  )
}

const HeroContent: React.FC<{ onApply: () => void }> = ({ onApply }) => {
  return (
    <div className="Hero-container ContentContainer is-hero">
      <div className="Hero-info is-mobile">
        <h1 className="Hero-title">
          A great webcam is coming. Be the first to meet it.
        </h1>
        <div className="Hero-lead">
          <p>The first professional-grade lens and sensor on a webcam.</p>
        </div>
      </div>
      <div className="Hero-info is-desktop">
        <h1 className="Hero-title">
          A great webcam is coming. Be the first to meet it.
        </h1>
        <div className="Hero-lead">
          <p>Sign up to be an Alpha tester.</p>
        </div>
      </div>

      <div>
        <button className="Button is-primary Hero-button" onClick={onApply}>
          Apply Now
        </button>
      </div>
    </div>
  )
}

const ApplicationForm = () => {
  const form = useRef<HTMLFormElement>()

  const [formData, setFormData] = useState({})
  const [isSending, setSending] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    setSending(false)

    if (isSending) {
      return false
    }

    axios
      .post('https://formkeep.com/f/56212351f4f7', formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true)
        } else {
          setError(true)
        }
      })
  }

  let errorMessage

  if (error) {
    errorMessage = (
      <AnimatePresence>
        <motion.div {...fadeInUp({ y: 20 })}>
          <p className="SplashForm-error">
            There was an error sending, please try again.
          </p>
        </motion.div>
      </AnimatePresence>
    )
  } else if (isSuccess) {
    return (
      <AnimatePresence>
        <motion.div {...fadeInUp({ y: 10 })}>
          <div className="SplashForm-success">
            Thanks, your submission has been sent!
          </div>
        </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <form
      action="https://formkeep.com/f/56212351f4f7"
      method="POST"
      className="SplashForm ContentContainer is-form"
      onSubmit={onSubmit}
    >
      <h2 className="SplashForm-title">Apply to Alpha Test</h2>
      {errorMessage}
      <div className="FormGroup">
        <div>
          <label className="Label" htmlFor="Full_Name">
            Name
          </label>
        </div>
        <div className="FormGroup-wrapper">
          <input
            type="text"
            id="Full_Name"
            name="Full_Name"
            minLength={2}
            maxLength={40}
            placeholder="Full name"
            required
            className="Input"
            autoComplete="off"
            onChange={setValue}
          />
          <img
            src={require('./checkmark.svg').default}
            className="InputValidCheckmark"
            alt=""
          />
        </div>
      </div>
      <div className="FormGroup">
        <div>
          <label className="Label" htmlFor="Email">
            Email
          </label>
        </div>
        <div className="FormGroup-wrapper">
          <input
            type="email"
            id="Email"
            placeholder="Email"
            name="Email"
            minLength={2}
            maxLength={100}
            required
            className="Input"
            autoComplete="off"
            onChange={setValue}
          />
          <img
            src={require('./checkmark.svg').default}
            className="InputValidCheckmark"
            alt=""
          />
        </div>
      </div>
      <div className="FormGroup">
        <div>
          <label className="Label" htmlFor="message">
            Why are you a great person to test?
          </label>
        </div>
        <div className="FormGroup-wrapper">
          <TextareaAutosize
            name="Message"
            id="Message"
            placeholder="Willing to chat through bugs? Tell us."
            required
            className="TextArea"
            minLength={5}
            maxLength={5000}
            autoComplete="off"
            // @ts-ignore
            onChange={setValue}
          />
          <img
            src={require('./checkmark.svg').default}
            className="InputValidCheckmark"
            alt=""
          />
        </div>
      </div>
      <button className="Button is-primary SplashForm-submit">Submit</button>
    </form>
  )
}

export default App
